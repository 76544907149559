<template>
  <Page mode="jacket">
    <div class="MainData">
     <IconSet class="mb-4" icon="success"  state=""></IconSet>
      <h1>{{ labels.Title }}</h1>
      <p v-html="labels.Description"></p>  
    </div>
  </Page>
</template>


<script>
import Page from "@/structure/Page.vue";
import axios from "axios";
import IconSet from '@/structure/IconSet.vue'
export default {
  name: "ThankYou",
  components: {
     Page,
    IconSet
  },
  data() {
    return {
      labels: {
        Title: "Kaerstin Zugang geloscht!",
        Description:
          "Ihr Zugang zu karestin wurde erfolgreich geloscht",
      },
    };
  },

  async beforeCreate() {
    try {
      const Url = process.env.VUE_APP_BASE_URL + `/v1/pages/thankyou`;
      const response = await axios.get(Url, {
        params: { language: `${this.$root.config.localization.language}` },
      });
      if (response.data.responseCode === "200" && response.data.response) {
        this.labels.Title = response.data.response.Title;
        this.labels.Description = response.data.response.Description;
      }
    } catch (e) {
      console.log("Error : " + e);
    }
  },
};
</script>

<style scoped >
.MainData{
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 79vh;
}
.vgs-page h1 { 
    margin-bottom: 5px;
}

.modal-body {
  padding-top: 0 !important;
}
h1 {
  padding-left: 0 !important;
  border: none;
}
.custom-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: transparent;
  color: black;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid black;
  outline: none;
}
.modal-content {
  border-radius: 0;
}
div#modal .modal-dialog.modal-md .modal-content {
  border-radius: 0 !important;
}
@media (max-width: 1024px) and (min-width: 768px) {
  .datepicker-input .b-form-btn-label-control.form-control>.form-control.form-control-sm {
    padding-left: 0.1rem;
    font-size: 9px;
    padding-top: 7px;
  }
  
}
@media (max-width: 382px) and (min-width: 280px) {
  .vgs-page h1 { 
    margin-bottom: 5px; 
    text-align: center;
  }
}
@media (min-width: 280px) and (max-width: 991px) {
  .MainData {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40vh;
  }
}
</style>